import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const CRMScreener = loadable(() => import("@/pages/CRM/Screener")); // prettier-ignore
const CRMScreenerAllLeads = loadable(() => import("@/pages/CRM/Screener/Leads/AllLeads")); // prettier-ignore
const CRMScreenerSingleLead = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead")); // prettier-ignore
const CRMScreenerAllSources = loadable(() => import("@/pages/CRM/Screener/Sources/AllSources")); // prettier-ignore
const CRMScreenerSingleSource = loadable(() => import("@/pages/CRM/Screener/Sources/SingleSource")); // prettier-ignore
const CRMScreenerRules = loadable(() => import("@/pages/CRM/Screener/Rules")); // prettier-ignore
const CRMScreenerSettings = loadable(() => import("@/pages/CRM/Screener/Settings")); // prettier-ignore
const CRMScreenerSettingsUsageOverview = loadable(() => import("@/pages/CRM/Screener/Settings/SettingsUsageOverview")); // prettier-ignore
const CRMScreenerSettingsUsageIntegrations = loadable(() => import("@/pages/CRM/Screener/Settings/SettingsIntegrations")); // prettier-ignore
const CRMScreenerDashboard = loadable(() => import("@/pages/CRM/Screener/Dashboard")); // prettier-ignore
const CRMScreenerViewPDF = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/components/LeadReview/ViewPDFPage")); // prettier-ignore

export enum CRMScreenerRoutes {
  CRMScreener = '/crm/screener',
  CRMScreenerAllSources = '/crm/screener/sources',
  CRMScreenerSingleSource = '/crm/screener/sources/:source_id',
  CRMScreenerAllLeads = '/crm/screener/leads',
  CRMScreenerSingleLead = '/crm/screener/leads/:lead_id',
  CRMScreenerRules = '/crm/screener/rules',
  CRMScreenerRulesDataPulls = '/crm/screener/rules/data-pulls',
  CRMScreenerSettings = '/crm/screener/settings',
  CRMScreenerSettingsUsageOverview = '/crm/screener/settings/usage-overview',
  CRMScreenerSettingsIntegrations = '/crm/screener/settings/integrations',
  CRMScreenerDashboard = '/crm/screener/dashboard',
  CRMScreenerUsageOverview = '/crm/screener/usage-overview',
  CRMScreenerViewPDF = '/crm/screener/leads/:lead_id/view-pdf'
}

export const getCrmScreenerRoutes = (): RouteObject[] => {
  return FeatureFlagService.isEnabled(ExacareFeature.CRM_SCREENER)
    ? [
        {
          path: CRMScreenerRoutes.CRMScreener,
          element: <CRMScreener />,
          children: [
            {
              path: CRMScreenerRoutes.CRMScreenerAllLeads,
              element: <CRMScreenerAllLeads />
            },
            {
              path: CRMScreenerRoutes.CRMScreenerAllSources,
              element: <CRMScreenerAllSources />
            },
            {
              path: CRMScreenerRoutes.CRMScreenerRules,
              element: <CRMScreenerRules />,
              children: [
                {
                  path: CRMScreenerRoutes.CRMScreenerRulesDataPulls,
                  element: <CRMScreenerRules />
                }
              ]
            },
            {
              path: CRMScreenerRoutes.CRMScreenerDashboard,
              element: <CRMScreenerDashboard />
            },
            {
              path: CRMScreenerRoutes.CRMScreenerSettings,
              element: <CRMScreenerSettings />,
              children: [
                {
                  path: CRMScreenerRoutes.CRMScreenerSettingsUsageOverview,
                  element: <CRMScreenerSettingsUsageOverview />
                },
                {
                  path: CRMScreenerRoutes.CRMScreenerSettingsIntegrations,
                  element: <CRMScreenerSettingsUsageIntegrations />
                }
              ]
            }
          ]
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLead,
          element: <CRMScreenerSingleLead />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerViewPDF,
          element: <CRMScreenerViewPDF />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleSource,
          element: <CRMScreenerSingleSource />
        }
      ]
    : [];
};
